import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Select from 'react-select'
import { NumericFormat } from 'react-number-format';
import { bdAxios } from '../../components/utils/Axios';
import { parseJwt, getToken } from '../../components/utils/Common';
import { useLocation } from "react-router-dom";

const RatioForm = (data: any) => {
    const [page, setPage] = useState<number>(0)
    const [year, setYear] = useState<any>(false)
    const [optYears, setOptYears] = useState<any>([])
    const [prelevements, setPrelevements] = useState<number>(119810)

    const [officeId, setOfficeId] = useState<any>()
    const [savedRatioId, setSavedRatioId] = useState<number>(0)
    const [configs, setConfigs] = useState<any>()
    const [selectedOfficeOption, setSelectedOfficeOption] = useState<any>()
    const [offices, setOffices] = useState<Array<any>>([]);
    const [officeOptions, setOfficeOptions] = useState<any>([])

    const [formData, setFormData] = useState<any>({})
    const [lastUpdated, setLastUpdated] = useState<string>('')
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const [nbEmployers, setNbEmployers] = useState<number>(0)

    const [ca, setCa] = useState<number>(0)
    const [honoraires, setHonoraires] = useState<number>(0)
    const [facturation, setFacturation] = useState<number>(0)

    const [salary, setSalary] = useState<number>(0);
    const [notarySalary, setNotarySalary] = useState<number>(0)

    const [expenses, setExpenses] = useState<number>(0);
    const [otherExpenses, setOtherExpenses] = useState<number>(0)
    const [totalExpenses, setTotalExpenses] = useState<number>(0)

    const [coutsFixes, setCoutsFixes] = useState<number>(0)
    const [coutsVariables, setCoutsVariables] = useState<number>(0)

    const [revImmobilier, setRevImmobilier] = useState<number>(0)
    const [revBusiness, setRevBusiness] = useState<number>(0)
    const [revIndiv, setRevIndiv] = useState<number>(0)
    const [revExpertises, setRevExpertises] = useState<number>(0)

    const [seuilRentabilite, setSeuilRentabilite] = useState<any>(0)


    const pages: any = [
        { 'label': 'Identification', index: 0 },
        { 'label': 'Statistiques RH', index: 1 },
        { 'label': 'Profil financier', index: 2 },
        { 'label': 'Salaires', index: 3 },
        { 'label': 'Frais d’exploitation', index: 4 },
        { 'label': 'Autres dépenses', index: 5 },
        { 'label': 'États financiers', index: 6 },
        { 'label': 'Droit Immobilier', index: 7 },
        { 'label': 'Droit des affaires', index: 8 },
        { 'label': 'Droit de la personne', index: 9 },
        { 'label': 'Expertises sectorielles', index: 10 },
        { 'label': 'Statistiques salariales', index: 11 },
        { 'label': 'Tarification', index: 12 },
        { 'label': 'Indicateurs de performance', index: 13 },
        { 'label': '', index: 14 }
    ];



    const getSeuil = () => {

        let upperPart: number = ca * coutsFixes

        let lowerPart: number = ca - coutsVariables

        setSeuilRentabilite(upperPart / lowerPart);
    }

    /**
     * Update formData for key and value
     * 
     * @param name 
     * @param value 
     */
    const setValue = async (name: string, value: any, update: string = '') => {

        let fValue = numericToFloat(value);

        let d: any = { ...formData, [name]: fValue };

        //setFormData(d);
        const v = await setLastUpdated(update)
        const u = await setFormData(d)


        switch (name) {
            case 'chiffre_affaires':
                setCa(fValue)
                break
            case 'honoraires':
                setHonoraires(fValue)
                break
            case 'facturation':
                setFacturation(fValue)
                break;
            case 'salaire_notaires_salaries':
                setNotarySalary(fValue)
                break
            case 'nb_notaires_salaries':
            case 'depense_loyer':
            case 'depense_inters_prets_marge':
            case 'depense_amortissement':
                break;
            case 'salary':
            case 'depense_debourses_dossiers':
            case 'depense_telecom':
            case 'depense_support_informatique':
            case 'depense_publicite':
                break;

        }

        /**
         * 		let upperPart: any = getNumValue('nb_notaires_salaries') * prelevements;
                upperPart = upperPart + getNumValue('depense_loyer') + getNumValue('depense_inters_prets_marge') + getNumValue('depense_amortissement')
                upperPart = upperPart * ca;
        
                let lowerPart: any = getNumValue('salary') + getNumValue('depense_debourses_dossiers')
                    + getNumValue('depense_telecom') + getNumValue('depense_support_informatique') + getNumValue('depense_publicite') + otherExpenses;
                lowerPart = ca - lowerPart;
         * 
         *  */

    }

    /**
     * Run calculation after formData is updated
     */
    useEffect(() => {
        switch (lastUpdated) {
            case 'depenses':
                let dep = updateDepenses()
                setExpenses(dep)
                break
            case 'autreDepenses':
                let other = updateAutreDepenses()
                setOtherExpenses(other)
                break
            case 'salary':
                let sal = updateSalary()
                setSalary(sal)
                break
        }

        if (lastUpdated == 'depenses' || lastUpdated == 'autreDepenses' || lastUpdated == 'salary') {
            let cf = updateCoutsFixes()
            let cv = updateCoutsVariables()
            setCoutsFixes(cf)
            setCoutsVariables(cv)
            setTotalExpenses(cf + cv)
            getSeuil()
        }

    }, [formData]);

    useEffect(() => {
        getSeuil()
        setTotalExpenses(expenses + otherExpenses)
    }, [expenses, otherExpenses])



    const getValue = (propName: string) => {
        if (formData.hasOwnProperty(propName)) {
            let value = numericToFloat(formData[propName]);
            return value;
        }

        return '';
    }

    const getSum = (keys: Array<string>) => {
        let s = 0.00;
        keys.forEach((key: string) => {
            let v = parseFloat(formData[key]) ?? 0;
            s += isNaN(v) ? 0 : v
        });
        s = Number(s.toFixed(2))
        return s
    }

    const getNumValue = (key: string) => {
        let v = getValue(key)
        let f = parseFloat(v)
        return f
    }

    const updateCoutsFixes = () => {
        let s = getSum([
            'depense_loyer',
            'depense_amortissement',
            'depense_inters_prets_marge'
        ])
        s += formData.nb_notaires_salaries * prelevements
        return s
    }

    const updateCoutsVariables = () => {
        let s = getSum([
            'depense_debourses_dossiers',
            'depense_telecom',
            'depense_support_informatique',
            'depense_publicite',
            'depense_firme_comptable',
            'depense_charges_sociales',
            'depense_assurance_responsabilite',
            'depense_cotisation_professionnelle',
            'depense_formation',
            'depense_formation_autre',
            'depense_mauvaises_creances',
            'autres_depenses'
        ])
        s += salary
        return s
    }


    const updateDepenses = () => {

        return getSum([
            'depense_loyer',
            'depense_amortissement',
            'depense_debourses_dossiers',
            'depense_inters_prets_marge',
            'depense_assurance_locataire',
            'depense_assurance_bureau_cyberassurance',
            'depense_cyberassurance',
            'depense_telecom',
            'depense_fournitures_de_bureau',
            'depense_equip_informatique',
            'depense_support_informatique',
            'depense_frais_pmeinter',
        ])

    }

    const updateAutreDepenses = () => {

        return getSum([
            'depense_firme_comptable',
            'depense_charges_sociales',
            'depense_publicite',
            'depense_assurance_responsabilite',
            'depense_cotisation_professionnelle',
            'depense_formation',
            'depense_formation_autre',
            'depense_mauvaises_creances',
            'autres_depenses'
        ]);

    }


    const updateBusiness = () => {

        return getSum([
            'service_corporatif',
            'corporatif',
            'corporatif_autre'
        ]);

    }

    const updateRevImmobilier = () => {
        return getSum([
            'immobilier_residentiel',
            'immobilier_comm_ind',
            'immobilier_autre'

        ]);
    }

    const updateIndiv = () => {
        return getSum([
            'testaments_mandats',
            'donations_fiduciaires',
            'successions',
            'autres_personne_familial',
        ])

    }
    const updateExpertises = () => {
        return getSum([
            'agricole',
            'exp_maritime',
            'donations_fiduciaires',
            'exp_autres'
        ])
    }


    const updateSalary = () => {

        return getSum([
            //'salaire_notaires_associes',
            'salaire_notaires_salaries',
            'salaire_coll',
            'salaire_avocats',
            'salaire_stagiaires',
            'salaire_stagiaires',
            'salaire_saisonniers',
            'salaire_autres'
        ])
    }



    const getIsActive = (pageToCheck: any) => {
        if (page === pageToCheck) {
            return "active";
        }

        return "";
    }


    // initial state
    useEffect(() => {
        let token = getToken();
        if (!token) {
            return;
        }

        if (data.selectedRatio) {
            setIsEditing(true);
            for (let key in data.selectedRatio.data) {
                setValue(key, data.selectedRatio.data[key]);
            }
            //setOtherExpenses(formData.totalExpenses);
            //setSalary(formData.salaires);
            //setRevImmobilier(formData.immobilier_total);
            //setRevBusiness(formData.commercial_corporatif);
            //setRevIndiv(formData.personne_succession_familial);
            //setRevExpertises(formData.exp_total);
            //
            //setHonoraires(formData.honoraires);
        }


        bdAxios.get('ratios/configs', {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then((r: any) => {
            setConfigs(r.data.configs);
            let found = false;
            let today = new Date().getTime()
            let editableYears: any = []
            r.data.configs.ratios.map((yrl: any) => {

                let endDate = new Date(yrl.end_date).getTime()
                let startDate = new Date(yrl.start_date).getTime()

                if (yrl.opened && today < endDate && today > startDate) {
                    editableYears.push({ label: yrl.year.toString(), value: parseInt(yrl.year) })
                }

                if (parseInt(yrl.year) === year && yrl.opened) {
                    setPrelevements(parseFloat(yrl.retainer));

                    found = true;
                }
            });

            setOptYears(editableYears)

            if (!found) {
                //setPrelevements(0);
            }

        });





        bdAxios.get('myoffices?light=1', {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then((r: any) => {
            r.data.offices.sort((a: any, b: any) => {
                return a.post_title.localeCompare(b.post_title);
            });

            setOffices(r.data.offices);

            const offOpt: Array<object> = [];
            for (let i in r.data.offices) {
                offOpt.push({ label: r.data.offices[i].post_title, value: r.data.offices[i].ID })
            }

            setOfficeOptions(offOpt);

            if (data?.selectedRatio) {
                r.data.offices.map((office: any) => {
                    if (office.ID == data.selectedRatio.data.office) {
                        setSelectedOfficeOption([{ label: office.post_title, value: office.ID }])
                    }
                })
            }
        });

    }, []);

    /**
     * Get Ratio from DB if Year and Office ID are changed
     */
    useEffect(() => {
        // if year and officeId not set, early bail
        if (!year || !officeId) {
            return
        }


        let token = (getToken() ? getToken() : '');
        if (token === null) {
            return;
        }

        data = { selectedRatio: { data: {} } }

        bdAxios.get('ratios/get', {
            headers: {
                'Authorization': "Bearer " + token
            },
            params: {
                year: year,
                officeId: officeId,
                forEditing: true
            }
        }).then((r: any) => {


            if (r.data.success) {
                //let ratioData = JSON.parse(r.data.data.ratio)
                let ratioData = r.data.data.ratio

                ratioData.user_name = ratioData.nom_utilisateur
                ratioData.user_phone = ratioData.phone_utilisateur
                ratioData.user_email = ratioData.email_utilisateur

                data.selectedRatio.data = ratioData

                if (data.selectedRatio) {

                    // set prelevement
                    configs.ratios.map((yrl: any) => {
                        if (parseInt(yrl.year) === year) {
                            setPrelevements(yrl.retainer)
                        }
                    });
                    setIsEditing(true);

                    setFormData(ratioData);

                    let s = updateSalary()
                    setSalary(s);

                    setRevImmobilier(formData.immobilier_total)
                    setRevBusiness(formData.commercial_corporatif)
                    setRevImmobilier(formData.personne_succession_familial)
                    setRevExpertises(formData.exp_total)

                    let ex = updateDepenses()
                    let ox = updateAutreDepenses()
                    setExpenses(ex)
                    setOtherExpenses(ox)

                    let cf = updateCoutsFixes()
                    let cv = updateCoutsVariables()
                    setCoutsFixes(cf)
                    setCoutsVariables(cv)
                    setTotalExpenses(cf + cv)
                    getSeuil()


                    setCa(formData.chiffre_affaires)

                }
            } else {
                alert(r.data.message)
            }

        });



    }, [year, officeId]);
    /* 
        useEffect(() => {
            let found = false;
            configs.annual_values.map((yrl: any) => {
                if (parseInt(yrl.year) === year) {
                    setPrelevements(yrl.retainer);
                    found = true;
                }
            });
            if (!found) {
                setPrelevements(0);
            }
        }, [year]); */



    const getPageClassName = (testedPage: number, isNumberPage: boolean = false) => {
        let cname: string = (page === testedPage ? 'active' : 'hidden');
        if (isNumberPage) {
            cname += ' number-fields-only'
        }

        return cname;
    }

    const getSelectedValue = (key: string, defaultV?: any) => {
        if (!data.selectedRatio) {
            if (defaultV) {
                return defaultV;
            }
            return '';
        }

        if (data.selectedRatio.data.hasOwnProperty(key)) {
            if (data.selectedRatio.data[key] instanceof String) {
                return (data.selectedRatio.data[key]);
            } else {
                return parseInt(data.selectedRatio.data[key]);
            }
        }

        if (defaultV) {
            return defaultV;
        }
        return 0;

    }
    const numericToFloat = (val: string): any => {
        if (false == /^[0-9 $,.]*$/.test(val)) {
            return val
        }
        if (typeof (val) == 'string') {
            val = val.replaceAll(/[ $]/g, '')
            val = val.replaceAll(',', '.')
        }

        return parseFloat(val)
    }
    const submit = (lpage: number) => {

        const form: any = document.getElementById('ratioForm');
        const yearInput: any = document.querySelector("[name=year]");
        //const formData = new FormData(form);

        formData.etude_id = officeId
        formData.year = year

        formData.chiffre_affaires = ca
        formData.honoraires = honoraires
        formData.facturation = facturation
        formData.salaire_notaires_salaries = notarySalary


        formData.expenses = expenses
        formData.otherExpenses = otherExpenses
        formData.totalExpenses = totalExpenses


        formData.salaires = updateSalary()
        formData.immobilier_total = updateRevImmobilier()
        formData.commercial_corporatif = updateBusiness()
        formData.exp_total = updateExpertises()
        formData.personne_succession_familial = updateIndiv()

        // calculations
        formData.seuilRentabilite = seuilRentabilite
        formData.ca_benefits = totalExpenses - prelevements
        formData.cases_month = formData.nb_dossiers / 12
        formData.minutes_month = formData.nb_minutes / 12

        if (ca) {
            formData.benefits_bprel = formData.benefices_nets / ca
            formData.point_mort = seuilRentabilite * 360 / ca
            formData.perc_salary = salary / ca
            formData.perc_notaries = notarySalary / ca
        }
        if (formData.total_passifs) {
            formData.fond_roulement = formData.total_actifs / formData.total_passifs
        }
        if (honoraires) {
            formData.perc_recevable = formData.recevables / honoraires
        }

        if (facturation) {
            formData.delay_percept = formData.recevables / facturation * 365
            formData.tec_sur_facturation_pourcent = formData.travaux_en_cours / facturation
        }
        if (formData.nb_notaires_salaries) {
            formData.avg_notary = notarySalary / formData.nb_notaires_salaries
            formData.cases_notary_year = formData.nb_dossiers / formData.nb_notaires_salaries
            formData.minutes_notary_year = formData.nb_minutes / formData.nb_notaires_salaries
            formData.collab_by_notary = formData.nb_employes / formData.nb_notaires_salaries
            formData.cost_per_hour = (totalExpenses - formData.depense_debourses_dossiers) / ((formData.nb_minutes / 60) * formData.nb_notaires_salaries)
            formData.avg_ca_notary = ca / formData.nb_notaires_salaries
        }
        if (formData.nb_dossiers) {
            formData.avg_cost_case = totalExpenses / formData.nb_dossiers
        }
        if (formData.nb_employes) {
            formData.avg_ca_collab = ca / formData.nb_employes
            formData.taux_de_roulement = formData.nb_employes_depart / formData.nb_employes
            formData.avg_collab = formData.salaire_coll / formData.nb_employes
        }
        if (formData.nb_minutes) {
            formData.ca_cases = ca / formData.nb_minutes
            formData.avg_rate_by_hour = honoraires / formData.nb_minutes / 60
        }

        let token = (getToken() ? getToken() : '');
        if (token === null) {
            return;
        }
        const user = parseJwt(token);

        if (user.data.roles && Array.isArray(user.data.roles)) {

        } else {

        }

        bdAxios.post('create/ratio', formData,
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then((r: any) => {
                setPage(lpage);
                setSavedRatioId(r.data.ratio_id)
            })
            .catch((err: any) => console.error(err))
    }

    const exportRatio = () => {
        let token = (getToken() ? getToken() : '');
        if (token === null) {
            return;
        }
        const formData: FormData = new FormData();
        formData.append('ratio_id', savedRatioId + '');

        bdAxios.post('ratios/export', formData,
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then((r: any) => {
                let i: any = document.querySelector("[name=office]");
                let a = document.createElement('a');
                a.setAttribute('href', r.data.data.excelData);
                a.setAttribute('download', i.value + '.xls');
                document.querySelector("body")?.append(a);
                a.click();
                a.remove();
            })
            .catch((err: any) => console.error(err))
    }

    const maybeSetPage = (lpage: number) => {
        if (page === pages.length - 1) {
            return false;
        }

        setPage(lpage);
    }

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [page]);

    /**
     * numeral formatter
     */
    const nb = new Intl.NumberFormat('fr-CA', { style: 'decimal', maximumFractionDigits: 3 })
    const money = new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' })
    const percent = new Intl.NumberFormat('fr-CA', { style: 'percent', maximumFractionDigits: 3 })

    return (
        <>
            <h2>Saisie de données</h2>
            <div className="formWrapper">
                <form action="" className="ratio-form" id="ratioForm">
                    <fieldset key={0} className={getPageClassName(0)}>
                        <legend>Identification</legend>
                        <label htmlFor="etude_id">Étude</label>
                        {
                            data?.selectedRatio ?
                                <>
                                    <input type="hidden" name="etude_id" readOnly defaultValue={selectedOfficeOption[0].value} />
                                    <input type="text" readOnly defaultValue={selectedOfficeOption[0].label} />
                                </>
                                :
                                <Select
                                    name="etude_id"
                                    defaultValue={officeId}
                                    onChange={(e) => { setOfficeId(e.value) }}
                                    options={officeOptions}
                                />
                        }

                        <br />

                        <label htmlFor="year">Année de saisie</label>

                        <Select
                            name="year"
                            //value={year}
                            defaultValue={year}
                            onChange={(e) => { setYear(e.value) }}
                            options={optYears}
                        />


                        <label htmlFor="user_name"><span className="numPoste">100.1</span> Nom de la personne responsable de la saisie des données pour l'étude</label>
                        <input value={formData.user_name ?? ''} name="user_name" onChange={(e) => { setValue('user_name', e.target.value) }} type="text" required />

                        <label htmlFor="user_email"><span className="numPoste">100.2</span> Courriel de la personne responsable</label>
                        <input value={formData.user_email ?? ''} name="user_email" onChange={(e) => { setValue('user_email', e.target.value) }} type="text" required />

                        <label htmlFor="user_phone"><span className="numPoste">100.3</span> Téléphone de la personne responsable</label>
                        <input value={formData.user_phone ?? ''} name="user_phone" onChange={(e) => { setValue('user_phone', e.target.value) }} type="text" required />

                        <div className="btnrow">
                            <span></span>
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={1} className={getPageClassName(1)}>
                        <legend>Statistiques RH</legend>

                        <label htmlFor="nb_notaires_associes"><span className="numPoste">101</span> Nombre de notaires associé(e)s</label>
                        <input type="number" step="1" value={formData.nb_notaires_associes} name="nb_notaires_associes" onChange={(e) => { setValue('nb_notaires_associes', e.target.value) }} required />

                        <label htmlFor="nb_notaires_salaries"><span className="numPoste">102</span> Nombre de notaires salarié(e)s </label>
                        <input type="number" step="1" value={formData.nb_notaires_salaries} name="nb_notaires_salaries" onChange={(e) => { setValue('nb_notaires_salaries', e.target.value) }} required />

                        <label htmlFor="nb_employes"><span className="numPoste">104</span> Nombre de collaborateur(rice)s</label>
                        <input type="number" step="1" value={formData.nb_employes} name="nb_employes" onChange={(e) => { setValue('nb_employes', e.target.value) }} required />

                        <label htmlFor="nb_avocats"><span className="numPoste">105</span> Nombre d’avocats(es) et/ou autres juristes</label>
                        <input type="number" step="1" value={formData.nb_avocats} name="nb_avocats" onChange={(e) => { setValue('nb_avocats', e.target.value) }} required />

                        <label htmlFor="nb_stagieres"><span className="numPoste">106</span> Nombre de stagiaire en notariat</label>
                        <input type="number" step="1" value={formData.nb_stagieres} name="nb_stagieres" onChange={(e) => { setValue('nb_stagieres', e.target.value) }} required />

                        <label htmlFor="nb_saisonniers"><span className="numPoste">107</span> Nombre d’employés saisonniers et/ou étudiants </label>
                        <input type="number" step="1" value={formData.nb_saisonniers} name="nb_saisonniers" onChange={(e) => { setValue('nb_saisonniers', e.target.value) }} required />

                        <label htmlFor="nb_autre"><span className="numPoste">108</span> Nombre de salariés occupant un poste autre que notaire ou collabo. (DG, Adj. Admin, récept., marketing, etc.)</label>
                        <input type="number" step="1" value={formData.nb_autre} name="nb_autre" onChange={(e) => { setValue('nb_autre', e.target.value) }} required />

                        <label htmlFor="nb_employes_temps_plein"><span className="numPoste">109</span> Nombre d’employés à temps plein (32hrs et + par semaine)</label>
                        <input type="number" step="1" value={formData.nb_employes_temps_plein} name="nb_employes_temps_plein" onChange={(e) => { setValue('nb_employes_temps_plein', e.target.value) }} required />

                        <label htmlFor="nb_employes_teletravail"><span className="numPoste">110</span> Nombre d’employés faisait plus de 60% de leur temps en télétravail</label>
                        <input type="number" step="1" value={formData.nb_employes_teletravail} name="nb_employes_teletravail" onChange={(e) => { setValue('nb_employes_teletravail', e.target.value) }} required />

                        <label htmlFor="nb_employes_arrive"><span className="numPoste">111</span> Nombre de nouveaux employés dans les derniers 12 mois</label>
                        <input type="number" step="1" value={formData.nb_employes_arrive} name="nb_employes_arrive" onChange={(e) => { setValue('nb_employes_arrive', e.target.value) }} required />

                        <label htmlFor="nb_employes_depart"><span className="numPoste">112</span> Nombre d’employés toute catégorie confondue ayant quitté l’entreprise dans les derniers 12 mois</label>
                        <input type="number" step="1" value={formData.nb_employes_depart} name="nb_employes_depart" onChange={(e) => { setValue('nb_employes_depart', e.target.value) }} required />

                        <label htmlFor="nb_succursales"><span className="numPoste">113</span> Nombre de succursales (incluant le siège social)</label>
                        <input type="number" step="1" value={formData.nb_succursales} name="nb_succursales" onChange={(e) => { setValue('nb_succursales', e.target.value) }} required />

                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={2} className={getPageClassName(2)}>
                        <legend>Profil financier (associés)</legend>

                        <label htmlFor="nb_dossiers"><span className="numPoste">201</span> Nombre de dossiers ouverts par l'étude</label>
                        <input type="number" step="1" value={formData.nb_dossiers} name="nb_dossiers" onChange={(e) => { setValue('nb_dossiers', e.target.value) }} />

                        <label htmlFor="nb_minutes"><span className="numPoste">202</span> Nombre de minutes signées par les notaires</label>
                        <input type="number" step="1" value={formData.nb_minutes} name="nb_minutes" onChange={(e) => { setValue('nb_minutes', e.target.value) }} />

                        <div>
                            <label htmlFor="honoraires"><span className="numPoste">203</span> Honoraires (excluant les taxes et déboursés)</label>
                            <NumericFormat
                                name="honoraires"
                                value={formData.honoraires}
                                onValueChange={(values, sourceInfo) => { setValue('honoraires', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            <div className="note">
                                Chiffre aux États Financiers moins les salaires des associés
                            </div>
                        </div>

                        <div>
                            <label htmlFor="facturation"><span className="numPoste">204</span> Facturation</label>
                            <NumericFormat
                                name="facturation"
                                value={formData.facturation}
                                onValueChange={(values, sourceInfo) => { setValue('facturation', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            <div className="note">
                                Honoraires + Débours + Taxes
                            </div>
                        </div>
                        <div>
                            <label htmlFor="chiffre_affaires"><span className="numPoste">205</span> Chiffre d’affaires</label>
                            <NumericFormat
                                name="chiffre_affaires"
                                value={formData.chiffre_affaires}
                                onValueChange={(values, sourceInfo) => { setValue('chiffre_affaires', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            <div className="note">
                                Honoraires + Débours + Autre revenus (mais pas les taxes)
                            </div>
                        </div>

                        <div>
                            <label htmlFor="benefices_nets"><span className="numPoste">206</span> Bénéfice net</label>
                            <NumericFormat
                                name="benefices_nets"
                                value={formData.benefices_nets}
                                onValueChange={(values, sourceInfo) => { setValue('benefices_nets', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />

                            <div className="note">
                                Chiffre aux États Financiers moins les salaires des associés
                            </div>
                        </div>


                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={3} className={getPageClassName(3, true)}>
                        <legend>301 Salaires</legend>

                        <div className="row">
                            <label htmlFor="salaire_notaires_salaries"><span className="numPoste">301.1</span> Salaire des notaires salarié(e)s</label>
                            <NumericFormat
                                name="salaire_notaires_salaries"
                                value={formData.salaire_notaires_salaries}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_notaires_salaries', values.value, 'salary'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="salaire_coll"><span className="numPoste">301.2</span> Salaire des collaborateur(rice)s </label>
                            <NumericFormat
                                name="salaire_coll"
                                value={formData.salaire_coll}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_coll', values.value, 'salary'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="salaire_avocats"><span className="numPoste">301.3</span> Salaire des avocats(es) et/ou autres juristes</label>
                            <NumericFormat
                                name="salaire_avocats"
                                value={formData.salaire_avocats}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_avocats', values.value, 'salary'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="salaire_stagiaires"><span className="numPoste">301.4</span> Salaire des stagiaires en notariat</label>
                            <NumericFormat
                                name="salaire_stagiaires"
                                value={formData.salaire_stagiaires}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_stagiaires', values.value, 'salary'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="salaire_saisonniers"><span className="numPoste">301.5</span> Salaire d’employés saisonniers</label>
                            <NumericFormat
                                name="salaire_saisonniers"
                                value={formData.salaire_saisonniers}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_saisonniers', values.value, 'salary') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="salaire_autres"><span className="numPoste">301.6</span> Salaire des salariés occupant un poste autre que notaire ou collabo. (DG, Adj. Admin, récept., marketing, etc.)</label>
                            <NumericFormat
                                name="salaire_autres"
                                value={formData.salaire_autres}
                                onValueChange={(values, sourceInfo) => { setValue('salaire_autres', values.value, 'salary') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>


                        <div className="row">
                            <label htmlFor="salaires"><span className="numPoste">301</span> Total des salaires</label>
                            <NumericFormat
                                readOnly
                                name="salaires"
                                value={[formData.salaire_notaires_salaries, formData.salaire_coll, formData.salaire_avocats, formData.salaire_stagiaires, formData.salaire_saisonniers, formData.salaire_autres].reduce((partialSum, a) => partialSum + (a || 0), 0)}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>


                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>


                    <fieldset key={4} className={getPageClassName(4, true)}>
                        <legend>Frais d’exploitation</legend>
                        <div className="row">
                            <label htmlFor="depense_loyer"><span className="numPoste">302</span> Loyer</label>
                            <NumericFormat
                                name="depense_loyer"
                                value={formData.depense_loyer}
                                onValueChange={(values, sourceInfo) => { setValue('depense_loyer', values.value, 'depenses'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="note">
                            Si locataire : le loyer versé au propriétaire + tous les frais afférents payés directement.<br />
                            Si propriétaire : frais d’intérêts + tous les frais afférents à l’immeubles.
                        </div>
                        <div className="row">
                            <label htmlFor="depense_amortissement"><span className="numPoste">303</span> Amortissement</label>
                            <NumericFormat
                                name="depense_amortissement"
                                value={formData.depense_amortissement}
                                onValueChange={(values, sourceInfo) => { setValue('depense_amortissement', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="depense_debourses_dossiers"><span className="numPoste">304</span> Débours de dossiers</label>
                            <NumericFormat
                                name="depense_debourses_dossiers"
                                value={formData.depense_debourses_dossiers}
                                onValueChange={(values, sourceInfo) => { setValue('depense_debourses_dossiers', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_inters_prets_marge"><span className="numPoste">305</span> Intérêts sur prêts et marge(s)</label>
                            <NumericFormat
                                name="depense_inters_prets_marge"
                                value={formData.depense_inters_prets_marge}
                                onValueChange={(values, sourceInfo) => { setValue('depense_inters_prets_marge', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>



                        <div className="row">
                            <label htmlFor="depense_assurance_bureau_cyberassurance"><span className="numPoste">306</span> Assurance bureau et cyberassurance</label>
                            <NumericFormat
                                name="depense_assurance_bureau_cyberassurance"
                                value={formData.depense_assurance_bureau_cyberassurance}
                                onValueChange={(values, sourceInfo) => { setValue('depense_assurance_bureau_cyberassurance', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>



                        <div className="row">
                            <label htmlFor="depense_telecom"><span className="numPoste">307</span> Télécommunications</label>
                            <NumericFormat
                                name="depense_telecom"
                                value={formData.depense_telecom}
                                onValueChange={(values, sourceInfo) => { setValue('depense_telecom', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_fournitures_de_bureau"><span className="numPoste">308</span> Messagerie/papetterie</label>
                            <NumericFormat
                                name="depense_fournitures_de_bureau"
                                value={formData.depense_fournitures_de_bureau}
                                onValueChange={(values, sourceInfo) => { setValue('depense_fournitures_de_bureau', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_equip_informatique"><span className="numPoste">309</span> Équipement et soutien informatique</label>
                            <NumericFormat
                                name="depense_equip_informatique"
                                value={formData.depense_equip_informatique}
                                onValueChange={(values, sourceInfo) => { setValue('depense_equip_informatique', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="depense_frais_pmeinter"><span className="numPoste">310</span> Frais d’utilisation du robot PME INTER Notaires</label>
                            <NumericFormat
                                name="depense_frais_pmeinter"
                                value={formData.depense_frais_pmeinter}
                                onValueChange={(values, sourceInfo) => { setValue('depense_frais_pmeinter', values.value, 'depenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={5} className={getPageClassName(5, true)}>
                        <legend>Autres dépenses</legend>
                        <div className="row">
                            <label htmlFor="depense_firme_comptable"><span className="numPoste">311</span> Firme comptable</label>
                            <NumericFormat
                                name="depense_firme_comptable"
                                value={formData.depense_firme_comptable}
                                onValueChange={(values, sourceInfo) => { setValue('depense_firme_comptable', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="depense_charges_sociales"><span className="numPoste">312</span> Charges sociales et avantages</label>
                            <NumericFormat
                                name="depense_charges_sociales"
                                value={formData.depense_charges_sociales}
                                onValueChange={(values, sourceInfo) => { setValue('depense_charges_sociales', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_publicite"><span className="numPoste">313</span> Publicité: représentation, évènement, web et réseaux sociaux</label>
                            <NumericFormat
                                name="depense_publicite"
                                value={formData.depense_publicite}
                                onValueChange={(values, sourceInfo) => { setValue('depense_publicite', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_assurance_responsabilite"><span className="numPoste">314</span> Assurance responsabilité</label>
                            <NumericFormat
                                name="depense_assurance_responsabilite"
                                value={formData.depense_assurance_responsabilite}
                                onValueChange={(values, sourceInfo) => { setValue('depense_assurance_responsabilite', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_cotisation_professionnelle"><span className="numPoste">315</span> Cotisation professionnelle</label>
                            <NumericFormat
                                name="depense_cotisation_professionnelle"
                                value={formData.depense_cotisation_professionnelle}
                                onValueChange={(values, sourceInfo) => { setValue('depense_cotisation_professionnelle', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="depense_formation"><span className="numPoste">316</span> Formation pour les notaires</label>
                            <NumericFormat
                                name="depense_formation"
                                value={formData.depense_formation}
                                onValueChange={(values, sourceInfo) => { setValue('depense_formation', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />

                        </div>
                        <div className="note">
                            Inclure les frais du congrès PME INTER Notaires
                        </div>
                        <div className="row">
                            <label htmlFor="depense_formation_autre"><span className="numPoste">317</span> Formation autres</label>
                            <NumericFormat
                                name="depense_formation_autre"
                                value={formData.depense_formation_autre}
                                onValueChange={(values, sourceInfo) => { setValue('depense_formation_autre', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />

                        </div>
                        <div className="row">
                            <label htmlFor="depense_mauvaises_creances"><span className="numPoste">318</span> Mauvaises créances</label>
                            <NumericFormat
                                name="depense_mauvaises_creances"
                                value={formData.depense_mauvaises_creances}
                                onValueChange={(values, sourceInfo) => { setValue('depense_mauvaises_creances', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="autres_depenses"><span className="numPoste">234</span> Autres dépenses</label>
                            <NumericFormat
                                name="autres_depenses"
                                value={formData.autres_depenses}
                                onValueChange={(values, sourceInfo) => { setValue('autres_depenses', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="autres_depenses"><span className="numPoste">319</span> Dépenses diverses (ex.frais de recrutement)</label>
                            <NumericFormat
                                name="autres_depenses"
                                value={formData.autres_depenses}
                                onValueChange={(values, sourceInfo) => { setValue('autres_depenses', values.value, 'autreDepenses') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={6} className={getPageClassName(6, true)}>
                        <legend>400 Renseigments - États financiers</legend>
                        <div className="row">
                            <label htmlFor="recevables"><span className="numPoste">401</span> Recevables à la fin de la période</label>
                            <NumericFormat
                                name="recevables"
                                value={formData.recevables}
                                onValueChange={(values, sourceInfo) => { setValue('recevables', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="travaux_en_cours"><span className="numPoste">402</span> Travaux en cours à la fin de la période (TEC)</label>
                            <NumericFormat
                                name="travaux_en_cours"
                                value={formData.travaux_en_cours}
                                onValueChange={(values, sourceInfo) => { setValue('travaux_en_cours', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="total_actifs"><span className="numPoste">403</span> Total des actifs à court terme - fin de période</label>
                            <NumericFormat
                                name="total_actifs"
                                value={formData.total_actifs}
                                onValueChange={(values, sourceInfo) => { setValue('total_actifs', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="total_passifs"><span className="numPoste">404</span> Total des passifs à court terme - fin de période</label>
                            <NumericFormat
                                name="total_passifs"
                                value={formData.total_passifs}
                                onValueChange={(values, sourceInfo) => { setValue('total_passifs', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="capital_associes"><span className="numPoste">405</span> Capitaux propres ou avoir des associés</label>
                            <NumericFormat
                                name="capital_associes"
                                value={formData.capital_associes}
                                onValueChange={(values, sourceInfo) => { setValue('capital_associes', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={7} className={getPageClassName(7, true)}>
                        <legend>501 Immobilier</legend>

                        <div className="row">
                            <label htmlFor="immobilier_existant"><span className="numPoste">501.1</span> Immobilier résidentiel</label>
                            <NumericFormat
                                name="immobilier_residentiel"
                                value={formData.immobilier_residentiel}
                                onValueChange={(values, sourceInfo) => { setValue('immobilier_residentiel', values.value, 'immobilier') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="immobilier_comm_ind"><span className="numPoste">501.2</span> Immobilier commercial & industriel</label>
                            <NumericFormat
                                name="immobilier_comm_ind"
                                value={formData.immobilier_comm_ind}
                                onValueChange={(values, sourceInfo) => { setValue('immobilier_comm_ind', values.value, 'immobilier') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="immobilier_autre"><span className="numPoste">501.3</span> Autres</label>
                            <NumericFormat
                                name="immobilier_autre"
                                value={formData.immobilier_autre}
                                onValueChange={(values, sourceInfo) => { setValue('immobilier_autre', values.value, 'immobilier') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="immobilier_total"><span className="numPoste">501</span> Total</label>
                            <NumericFormat
                                readOnly
                                name="immobilier_total"
                                value={[formData.immobilier_residentiel, formData.immobilier_comm_ind, formData.immobilier_autre].reduce((partialSum, a) => partialSum + (a || 0), 0)}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={8} className={getPageClassName(8, true)}>
                        <legend>502 Droit des affaires</legend>

                        <div className="row">
                            <label htmlFor="service_corporatif"><span className="numPoste">502.1</span> Service Corporatif / forfait entrepreneur:</label>
                            <NumericFormat
                                name="service_corporatif"
                                value={formData.service_corporatif}
                                onValueChange={(values, sourceInfo) => { setValue('service_corporatif', values.value, 'business'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="corporatif"><span className="numPoste">502.2</span> Droit corporatif</label>
                            <NumericFormat
                                name="corporatif"
                                value={formData.corporatif}
                                onValueChange={(values, sourceInfo) => { setValue('corporatif', values.value, 'business') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>


                        <div className="row">
                            <label htmlFor="corporatif_autre"><span className="numPoste">502.3</span>Autres</label>
                            <NumericFormat
                                name="corporatif_autre"
                                value={formData.corporatif_autre}
                                onValueChange={(values, sourceInfo) => { setValue('corporatif_autre', values.value, 'business') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="commercial_corporatif"><span className="numPoste">502</span> Total</label>
                            <NumericFormat
                                readOnly
                                name="commercial_corporatif"
                                value={[formData.service_corporatif, formData.corporatif, formData.corporatif_autre].reduce((partialSum, a) => partialSum + (a || 0), 0)}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>


                    <fieldset key={9} className={getPageClassName(9, true)}>
                        <legend>503 Droit de la personne</legend>
                        <div className="row">
                            <label htmlFor="testaments_mandats"><span className="numPoste">503.1</span> Testaments, mandats et DMA</label>
                            <NumericFormat
                                name="testaments_mandats"
                                value={formData.testaments_mandats}
                                onValueChange={(values, sourceInfo) => { setValue('testaments_mandats', values.value, 'indiv') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="successions"><span className="numPoste">503.2</span> Droit de la personne (succession et famille excluant 503.1) </label>
                            <NumericFormat
                                name="successions"
                                value={formData.successions}
                                onValueChange={(values, sourceInfo) => { setValue('successions', values.value, 'indiv') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="autres_personne_familial"><span className="numPoste">503.3</span> Autres</label>
                            <NumericFormat
                                name="autres_personne_familial"
                                value={formData.autres_personne_familial}
                                onValueChange={(values, sourceInfo) => { setValue('autres_personne_familial', values.value, 'indiv'); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="personne_succession_familial"><span className="numPoste">503</span> Total</label>
                            <NumericFormat
                                readOnly
                                name="personne_succession_familial"
                                value={[formData.testaments_mandats, formData.successions, formData.autres_personne_familial].reduce((partialSum, a) => partialSum + (a || 0), 0)}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>


                    <fieldset key={10} className={getPageClassName(10, true)}>
                        <legend>504 Expertises sectorielles</legend>
                        <div className="row">
                            <label htmlFor="agricole"><span className="numPoste">504.1</span> Agricole</label>
                            <NumericFormat
                                name="agricole"
                                value={formData.agricole}
                                onValueChange={(values, sourceInfo) => { setValue('agricole', values.value, 'expertises') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="exp_maritime"><span className="numPoste">504.2</span> Maritime</label>
                            <NumericFormat
                                name="exp_maritime"
                                value={formData.exp_maritime}
                                onValueChange={(values, sourceInfo) => { setValue('exp_maritime', values.value, 'expertises') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="donations_fiduciaires"><span className="numPoste">504.3</span> Fiducie (donation fiduciaire, testament fiduciaire)</label>
                            <NumericFormat
                                name="donations_fiduciaires"
                                value={formData.donations_fiduciaires}
                                onValueChange={(values, sourceInfo) => { setValue('donations_fiduciaires', values.value, 'expertises') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="exp_autres"><span className="numPoste">504.4</span> Autres</label>
                            <NumericFormat
                                name="exp_autres"
                                value={formData.exp_autres}
                                onValueChange={(values, sourceInfo) => { setValue('exp_autres', values.value, 'expertises') }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>


                        <div className="row">
                            <label htmlFor="exp_total"><span className="numPoste">504</span> Total</label>
                            <NumericFormat
                                readOnly
                                name="exp_total"
                                value={[formData.agricole, formData.exp_maritime, formData.donations_fiduciaires].reduce((partialSum, a) => partialSum + (a || 0), 0)}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>

                    <fieldset key={11} className={getPageClassName(11)}>
                        <legend>600 Statistiques salariales</legend>

                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">601</span> Salaire des notaires salarié(e)s</span>

                            <div className="row">
                                <label htmlFor="salaire_notaires_salaries_min">Minimum</label>
                                <NumericFormat
                                    name="salaire_notaires_salaries_min"
                                    value={formData.salaire_notaires_salaries_min}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_notaires_salaries_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="salaire_notaires_salaries_max">Maximum</label>
                                <NumericFormat
                                    name="salaire_notaires_salaries_max"
                                    value={formData.salaire_notaires_salaries_max}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_notaires_salaries_max', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>
                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">602</span> Salaire des collaborateur(rice)s</span>

                            <div className="row">
                                <label htmlFor="salaire_collab_min">Minimum</label>
                                <NumericFormat
                                    name="salaire_collab_min"
                                    value={formData.salaire_collab_min}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_collab_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="salaire_collab_max">Maximum</label>
                                <NumericFormat
                                    name="salaire_collab_max"
                                    value={formData.salaire_collab_max}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_collab_max', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>
                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">603</span> Salaires des d’avocats(es) et/ou autres juristes</span>

                            <div className="row">
                                <label htmlFor="salaire_avocat_min">Minimum</label>
                                <NumericFormat
                                    name="salaire_avocat_min"
                                    value={formData.salaire_avocat_min}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_avocat_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="salaire_avocat_max">Maximum</label>
                                <NumericFormat
                                    name="salaire_avocat_max"
                                    value={formData.salaire_avocat_max}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_avocat_max', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>
                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">604</span> Salaire des stagiaires en notariat</span>

                            <div className="row">
                                <label htmlFor="stagiaire_notariat_min">Minimum</label>
                                <NumericFormat
                                    name="stagiaire_notariat_min"
                                    value={formData.stagiaire_notariat_min}
                                    onValueChange={(values, sourceInfo) => { setValue('stagiaire_notariat_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="stagiaire_notariat">Maximum</label>
                                <NumericFormat
                                    name="stagiaire_notariat"
                                    value={formData.stagiaire_notariat}
                                    onValueChange={(values, sourceInfo) => { setValue('stagiaire_notariat', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>
                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">605</span> Salaire d’employés saisonniers</span>
                            <div className="row">
                                <label htmlFor="salaire_saisonnier_min">Minimum</label>
                                <NumericFormat
                                    name="salaire_saisonnier_min"
                                    value={formData.salaire_saisonnier_min}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_saisonnier_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="salaire_saisonnier_max">Maximum</label>
                                <NumericFormat
                                    name="salaire_saisonnier_max"
                                    value={formData.salaire_saisonnier_max}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_saisonnier_max', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>
                        <div className="min-max-row">
                            <span className="row-title"><span className="numPoste">606</span> Salaire des salariés occupant un poste autre que notaire ou collabo. (DG, Adj. Admin, récept., marketing, etc.)</span>

                            <div className="row">
                                <label htmlFor="salaire_autre_min">Minimum</label>
                                <NumericFormat
                                    name="salaire_autre_min"
                                    value={formData.salaire_autre_min}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_autre_min', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="salaire_autre_max">Maximum</label>
                                <NumericFormat
                                    name="salaire_autre_max"
                                    value={formData.salaire_autre_max}
                                    onValueChange={(values, sourceInfo) => { setValue('salaire_autre_max', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>


                        <div className="number-fields-only">

                            <div className="row">
                                <label htmlFor="taux_horaire_notaire_moins_de_5_ans"><span className="numPoste">607</span> Notaire de moins de 5 ans d’expérience ?</label>
                                <NumericFormat
                                    name="taux_horaire_notaire_moins_de_5_ans"
                                    value={formData.taux_horaire_notaire_moins_de_5_ans}
                                    onValueChange={(values, sourceInfo) => { setValue('taux_horaire_notaire_moins_de_5_ans', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="taux_horaire_notaire_5_a_10_ans"><span className="numPoste">608</span> Notaire de 5 à 10 ans d’expérience ?</label>
                                <NumericFormat
                                    name="taux_horaire_notaire_5_a_10_ans"
                                    value={formData.taux_horaire_notaire_5_a_10_ans}
                                    onValueChange={(values, sourceInfo) => { setValue('taux_horaire_notaire_5_a_10_ans', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="taux_horaire_notaire_plus_de_10_ans"><span className="numPoste">609</span> Notaire de plus de 10 ans d’expérience</label>
                                <NumericFormat
                                    name="taux_horaire_notaire_plus_de_10_ans"
                                    value={formData.taux_horaire_notaire_plus_de_10_ans}
                                    onValueChange={(values, sourceInfo) => { setValue('taux_horaire_notaire_plus_de_10_ans', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                            <div className="row">
                                <label htmlFor="taux_horaire_collaborateur"><span className="numPoste">610</span> Collaborateur(rices)s ?</label>
                                <NumericFormat
                                    name="taux_horaire_collaborateur"
                                    value={formData.taux_horaire_collaborateur}
                                    onValueChange={(values, sourceInfo) => { setValue('taux_horaire_collaborateur', values.value); }}
                                    decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                            </div>
                        </div>

                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Suivant" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>




                    <fieldset key={12} className={getPageClassName(12, true)}>
                        <legend>700 Tarification</legend>

                        <h5>Quel est votre forfait honoraires (sans frais et taxes) pour: </h5>
                        <div className="row">
                            <label htmlFor="forfait_honoraires_residentiel"><span className="numPoste">701</span> un prêt/vente résidentiel pour une propriété d’une valeur de 400 000 $ et moins ?</label>
                            <NumericFormat
                                name="forfait_honoraires_residentiel"
                                value={formData.forfait_honoraires_residentiel}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_residentiel', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>
                        <div className="row">
                            <label htmlFor="forfait_honoraires_testaments"><span className="numPoste">702</span> deux testaments et mandats (couple) simples ?</label>
                            <NumericFormat
                                name="forfait_honoraires_testaments"
                                value={formData.forfait_honoraires_testaments}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_testaments', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_corporatif"><span className="numPoste">703</span> le service corporatif de base ?</label>
                            <NumericFormat
                                name="forfait_honoraires_corporatif"
                                value={formData.forfait_honoraires_corporatif}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_corporatif', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_feducie"><span className="numPoste">704</span> pour le service fiducie de base ?</label>
                            <NumericFormat
                                name="forfait_honoraires_feducie"
                                value={formData.forfait_honoraires_feducie}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_feducie', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_hypotheque"><span className="numPoste">705</span> une quittance ou mainlevée - hypothèque d’un vendeur d’une propriété résidentiel ?</label>
                            <NumericFormat
                                name="forfait_honoraires_hypotheque"
                                value={formData.forfait_honoraires_hypotheque}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_hypotheque', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_incorporation"><span className="numPoste">706</span> une nouvelle incorporation ?</label>
                            <NumericFormat
                                name="forfait_honoraires_incorporation"
                                value={formData.forfait_honoraires_incorporation}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_incorporation', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <h5>Quels sont vos honoraires estimés (sans frais et taxes) pour </h5>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_hmn"><span className="numPoste">707</span> un dossier d’homologation de mandat devant notaire ?</label>
                            <NumericFormat
                                name="forfait_honoraires_hmn"
                                value={formData.forfait_honoraires_hmn}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_hmn', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_rpm"><span className="numPoste">708</span> un dossier d’ouverture de tutelle au majeur ?</label>
                            <NumericFormat
                                name="forfait_honoraires_rpm"
                                value={formData.forfait_honoraires_rpm}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_rpm', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_htn"><span className="numPoste">709</span> un dossier d’homologation de testament devant notaire ?</label>
                            <NumericFormat
                                name="forfait_honoraires_htn"
                                value={formData.forfait_honoraires_htn}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_htn', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_cp_div"><span className="numPoste">710</span> une copropritété divise</label>
                            <NumericFormat
                                name="forfait_honoraires_cp_div"
                                value={formData.forfait_honoraires_cp_div}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_cp_div', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_convention_actionnaire"><span className="numPoste">711</span> une convention d’actionnaires?</label>
                            <NumericFormat
                                name="forfait_honoraires_convention_actionnaire"
                                value={formData.forfait_honoraires_convention_actionnaire}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_convention_actionnaire', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_pnc"><span className="numPoste">712</span> une PNC (excluant les dossiers 707)</label>
                            <NumericFormat
                                name="forfait_honoraires_pnc"
                                value={formData.forfait_honoraires_pnc}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_pnc', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_conv_achat_vente"><span className="numPoste">713</span> une convention d’achat-vente (d’actions ou d’entreprises) </label>
                            <NumericFormat
                                name="forfait_honoraires_conv_achat_vente"
                                value={formData.forfait_honoraires_conv_achat_vente}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_conv_achat_vente', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <h5>Combien chargez-vous en montant supplémentaire pour </h5>
                        <div className="row">
                            <label htmlFor="forfait_honoraires_acte_techno"><span className="numPoste">714</span> un acte technologique (1h)</label>
                            <NumericFormat
                                name="forfait_honoraires_acte_techno"
                                value={formData.forfait_honoraires_acte_techno}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_acte_techno', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_reception_signature"><span className="numPoste">715</span> les frais de réception de signature devant notaires</label>
                            <NumericFormat
                                name="forfait_honoraires_reception_signature"
                                value={formData.forfait_honoraires_reception_signature}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_reception_signature', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_cc_client"><span className="numPoste">716</span> une copie conforme pour un client</label>
                            <NumericFormat
                                name="forfait_honoraires_cc_client"
                                value={formData.forfait_honoraires_cc_client}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_cc_client', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="row">
                            <label htmlFor="forfait_honoraires_cc_juriste"><span className="numPoste">717</span> une copie conforme pour un autre juriste</label>
                            <NumericFormat
                                name="forfait_honoraires_cc_juriste"
                                value={formData.forfait_honoraires_cc_juriste}
                                onValueChange={(values, sourceInfo) => { setValue('forfait_honoraires_cc_juriste', values.value); }}
                                decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " suffix=" $" />
                        </div>

                        <div className="btnrow">
                            <input type="button" defaultValue="Précédent" className="back" onClick={() => submit(page - 1)} />
                            <input type="button" defaultValue="Validation" className="next" onClick={() => submit(page + 1)} />
                        </div>
                    </fieldset>


                    <fieldset key={13} className={getPageClassName(13)}>

                        <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800">
                            Merci d’avoir participé aux ratios de gestion. Le siège social vous contactera lorsque les comparatifs seront disponibles.
                        </div>

                        <legend>Indicateurs de performance</legend>
                        <div className="results">
                            <div className="title">Pourcentage profit (bénéfice) avant prélèvement</div>
                            <div className="result" data-value="benefits_bprel">
                                {percent.format(formData.benefices_nets / ca)}
                            </div>
                            <div className="result-details">Bénéfices Net ({money.format(formData.benefices_nets)}) ÷ Chiffre d’affaire({money.format(ca)}) </div>
                        </div>
                        <div className="results">
                            <div className="title">Ratio de Fond de roulement</div>
                            <div className="result" data-value="fond_roulement">
                                {nb.format(formData.total_actifs / formData.total_passifs)}
                            </div>
                            <div className="result-details">Total des actifs ({money.format(formData.total_actifs)}) ÷ Total des passifs ({money.format(formData.total_passifs)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Seuil de rentabilité</div>
                            <div className="result" data-value="seuilRentabilite">
                                {money.format(seuilRentabilite)}
                            </div>
                            <div className="result-details"><span style={{ borderBottom: '1px solid black', display: 'inline-block', padding: '0 1em' }}>Chiffre d’affaire ({money.format(ca)}) × Coûts fixes ({money.format(coutsFixes)})</span> <span style={{ display: 'inline-block', padding: '0 1em' }}>Chiffre d’affaire ({money.format(ca)}) — Coûts variables ({money.format(coutsVariables)})</span></div>
                        </div>

                        <div className="results">
                            <div className="title">Point mort</div>
                            <div className="result" data-value="point_mort">
                                {money.format(seuilRentabilite * 360 / ca)}
                            </div>
                            <div className="result-details">Seuil de rentabilité ({money.format(seuilRentabilite)}) × 360 ÷ Chiffre d’affaire ({money.format(ca)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Chiffre d’affaire qui aurait permis de dégager un bénéfice net de 15% après les prélèvements</div>
                            <div className="result" data-value="ca_benefits">
                                {money.format(totalExpenses - prelevements)}
                            </div>
                            <div className="result-details">Total des dépenses ({money.format(totalExpenses)}) - Prélévements ({money.format(prelevements)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Pourcentage des recevables sur facturation</div>
                            <div className="result" data-value="perc_recevable">
                                {percent.format(formData.recevables / honoraires)}
                            </div>
                            <div className="result-details">Recevables ({money.format(formData.recevables)}) ÷ Facturation ({money.format(facturation)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Délai de perception</div>
                            <div className="result" data-value="delay_percept">
                                {nb.format(formData.recevables / facturation * 365)} jours
                            </div>
                            <div className="result-details">Recevables ({money.format(formData.recevables)}) ÷ Facturation ({money.format(facturation)}) × 365</div>
                        </div>

                        <div className="results">
                            <div className="title">Pourcentage des travaux en cours (TEC) sur facturation</div>
                            <div className="result" data-value="tec_sur_facturation_pourcent">
                                {percent.format(formData.travaux_en_cours / facturation)}
                            </div>
                            <div className="result-details">TEC ({money.format(formData.travaux_en_cours)}) ÷ Facturation ({money.format(facturation)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Pourcentage salaires sur chiffre d’affaires</div>
                            <div className="result" data-value="perc_salary">
                                {percent.format(salary / ca)}
                            </div>
                            <div className="result-details">Salaires ({money.format(salary)}) ÷ Chiffre d’affaire ({money.format(ca)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Pourcentage salaires notaires sur chiffre d’affaires</div>
                            <div className="result" data-value="perc_notaries">
                                {percent.format(notarySalary / ca)}
                            </div>
                            <div className="result-details">Salaires des notaires ({money.format(notarySalary)}) ÷ Chiffre d’affaire ({money.format(ca)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Salaires et avantages - moyenne par notaire salarié</div>
                            <div className="result" data-value="avg_notary">
                                {money.format(notarySalary / formData.nb_notaires_salaries)}
                            </div>
                            <div className="result-details">Salaires des notaires ({money.format(notarySalary)}) ÷ Nombre de notaires salariés ({nb.format(formData.nb_notaires_salaries)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Salaires et avantages - moyenne par collabo</div>
                            <div className="result" data-value="avg_collab">
                                {money.format(formData.salaire_coll / formData.nb_employes)}
                            </div>
                            <div className="result-details">Salaires des collaborateurs ({money.format(formData.salaire_coll)}) ÷ Nombre de collaborateurs ({formData.nb_employes})</div>

                        </div>

                        <div className="results">
                            <div className="title">Dossiers / mois</div>
                            <div className="result" data-value="cases_month">
                                {nb.format(formData.nb_dossiers / 12)}
                            </div>
                            <div className="result-details">Nombre de dossiers ({formData.nb_dossiers}) ÷ 12</div>
                        </div>

                        <div className="results">
                            <div className="title">Minutes / mois</div>
                            <div className="result" data-value="minutes_month">
                                {nb.format(formData.nb_minutes / 12)}
                            </div>
                            <div className="result-details">Nombre de minutes ({formData.nb_minutes}) ÷ 12</div>
                        </div>

                        <div className="results">
                            <div className="title">Dossiers / notaire / année</div>
                            <div className="result" data-value="cases_notary_year">
                                {nb.format(formData.nb_dossiers / formData.nb_notaires_salaries)}
                            </div>
                            <div className="result-details">Nombre de dossiers ({formData.nb_dossiers}) ÷ Nombre de notaires salariés ({nb.format(formData.nb_notaires_salaries)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Minutes / notaire / année</div>
                            <div className="result" data-value="minutes_notary_year">
                                {nb.format(formData.nb_minutes / formData.nb_notaires_salaries)}
                            </div>
                            <div className="result-details">Nombre de minutes ({formData.nb_minutes}) ÷ Nombre de notaires salariés ({nb.format(formData.nb_notaires_salaries)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Coût moyen / dossier</div>
                            <div className="result" data-value="avg_cost_case">
                                {money.format(totalExpenses / formData.nb_dossiers)}
                            </div>
                            <div className="result-details">Total des dépenses ({money.format(totalExpenses)}) ÷ Nombre de dossiers ({formData.nb_dossiers})</div>
                        </div>

                        <div className="results">
                            <div className="title">Chiffre d’affaires (CA) / dossier</div>
                            <div className="result" data-value="ca_cases">
                                {money.format(ca / formData.nb_dossiers)}
                            </div>
                            <div className="result-details">Chiffre d’affaire ({money.format(ca)}) ÷ Nombre de dossiers ({formData.nb_dossiers})</div>
                        </div>

                        <div className="results">
                            <div className="title">Chiffre d’affaires (CA) / minute</div>
                            <div className="result" data-value="ca_minutes">
                                {money.format(ca / formData.nb_minutes)}
                            </div>
                            <div className="result-details">Chiffre d’affaire ({money.format(ca)}) ÷ Nombre de minutes ({formData.nb_minutes})</div>
                        </div>

                        <div className="results">
                            <div className="title">Nombre de collaborateurs par notaire</div>
                            <div className="result" data-value="collab_by_notary">
                                {nb.format(formData.nb_employes / formData.nb_notaires_salaries)}
                            </div>
                            <div className="result-details">Nombre de collaborateurs ({nb.format(formData.nb_employes)}) ÷ Nombre de notaires ({nb.format(formData.nb_notaires_salaries)})</div>
                        </div>

                        <div className="results">
                            <div className="title">Chiffre d’affaires moyen par notaire</div>
                            <div className="result" data-value="avg_ca_notary">
                                {money.format(ca / formData.nb_notaires_salaries)}
                            </div>
                            <div className="result-details">Chiffre d’affaire ({money.format(ca)}) ÷ Nombre de notaires ({formData.nb_notaires_salaries})</div>
                        </div>

                        <div className="results">
                            <div className="title">Chiffre d’affaires moyen par collaborateur (trice)</div>
                            <div className="result" data-value="avg_ca_collab">
                                {money.format(ca / formData.nb_employes)}
                            </div>
                            <div className="result-details">Chiffre d’affaire ({money.format(ca)}) ÷ Nombre de collaborateur ({formData.nb_employes})</div>
                        </div>

                        <div className="results">
                            <div className="title">Coût par heure facturable</div>
                            <div className="result" data-value="cost_per_hour">
                                {money.format((totalExpenses - formData.depense_debourses_dossiers) / ((formData.nb_minutes / 60) * formData.nb_notaires_salaries))}/h
                            </div>
                            <div className="result-details"><span style={{ borderCollapse: 'collapse', display: 'inline-block', padding: '0 1em' }}>Total des dépenses ({money.format(totalExpenses)}) - Débours ({money.format(formData.depense_debourses_dossiers)}) </span><span style={{ borderCollapse: 'collapse', borderTop: '1px solid black', display: 'inline-block', padding: '0 1em' }}> Nombre de minutes({formData.nb_minutes}) ÷ 60 × Nombre de notaires ({nb.format(formData.nb_notaires_salaries)})</span></div>
                        </div>

                        <div className="results">
                            <div className="title">Taux horaire moyen (honoraires par heure facturable)</div>
                            <div className="result" data-value="avg_rate_by_hour">
                                {money.format(honoraires / formData.nb_minutes / 60)}/h
                            </div>
                            <div className="result-details">Honoraires ({money.format(honoraires)}) ÷ Nombre de minutes ({formData.nb_minutes}) ÷ 60</div>

                        </div>
                        <div className="results">
                            <div className="title">Taux de roulement</div>
                            <div className="result" data-value="taux_de_roulement">
                                {percent.format(formData.nb_employes_depart / formData.nb_employes)}
                            </div>
                            <div className="result-details">Nombre de départs({formData.nb_employes_depart}) ÷ Nombre d’employés ({formData.nb_employes}) </div>
                        </div>


                        <div className="btnrow">
                            <input type="button" defaultValue="Modifier" className="back" onClick={() => submit(0)} />
                            <input type="button" defaultValue="Exporter le ratio" className="next" onClick={exportRatio} />
                        </div>


                    </fieldset>

                </form>

                <div className="sidebar">
                    <div className="page-index">Page {page + 1} / {pages.length}</div>
                    <nav className="page-list">
                        {
                            pages.map((page: any) => {
                                return (
                                    <li key={page.index} className={getIsActive(page.index)} onClick={e => maybeSetPage(page.index)}>{page.label}</li>
                                )
                            })
                        }
                    </nav>
                </div>
            </div>
        </>
    );
}

export default RatioForm;