import * as React from 'react';
import { useState, useEffect } from 'react';
import { bdAxios } from '../../components/utils/Axios';
import { getToken } from '../../components/utils/Common';

const ShowOldRatioList = (data: any) => {
	const [ratioLength, setRatioLength] = useState<any>();
	const [oldFormData, setOldFormData] = useState<any>();

	useEffect(() => {
		if (data.ratios) {
			setRatioLength([...Array(data.ratios.length).keys()]);
		}

		if (data.fdata) {
			setOldFormData(data.fdata);
		}
	},[]);

	const exportData = () => {
	 	let token = (getToken() ? getToken() : '');
	 	if (token === null) {
	 		return;
	 	}
	 	const form: any = document.querySelector("#export-form");
	 	const formData = new FormData();
	 	for (let i in oldFormData) {
	 		formData.append(i, oldFormData[i]);
	 	}
	 	bdAxios.post('ratios/exportold', formData, {
	 		headers: {
				'Authorization': "Bearer " + token
			}
	 	}).then(r => {
	 		// setFExports(r.data.data.excelData);
	 		let a = document.createElement('a');
	 		a.setAttribute('href', r.data.data.excelData);
	 		// a.setAttribute('download', +'.xls');
	 		document.querySelector("body")?.append(a);
	 		a.click();
	 		a.remove();
	 	}).catch(err => console.error(err));
	}

	return (
		<>
			<div className="old-ratio old-ratio-list">
				<input onClick={exportData} type="button" defaultValue="Exporter" className="button" />

				<table>
					<thead>
						<tr>
						<td></td>
						{ 
						
							ratioLength ?
								ratioLength.map((l:any) => {
									return <th key={l} className="value-only" title={data.ratios[l].etude_nom} >{data.ratios[l].colonne}</th>
								})
							:void(0)
						}
						</tr>
					</thead>
					<tbody>
					{ 
						data.humanKeys ?
							Object.keys(data.humanKeys).map((key: any, index: any) => {
								
								// check if row is empty
								let canSkip = true;

								(ratioLength && ratioLength.length > 0) ?
												ratioLength.map((i:any) => {
													if ( data.ratios[i][key] ) {
														canSkip = false
													}
												})
												: void(0)

								if ( ['nom_utilisateur','complete'].includes(key) ) {
									canSkip = true
								}

								if ( canSkip ){

									return '';
								}

								return (
									<tr>
										<td className="key-name" title={key}>{data.humanKeys[key]}</td>
										{
											(ratioLength && ratioLength.length > 0) ?
												ratioLength.map((i:any) => {
													
													let val = parseFloat( data.ratios[i][key] )
													let strVal = data.humanKeys[key]
													let intKeys = [
														'accepte_mandats_exterieurs',
														'numerisation_pieces',

													]
													let percentKeys = [
														'taux_remboursement'
													]

													if ( undefined === data.ratios[i][key] ){
														strVal = ''
													} else if ( isNaN(val) || ! val ) {
														strVal = data.ratios[i][key]
														if ( undefined === strVal ){
															strVal = key
														}
														// ellipse long text
														if ( strVal.length > 70 ){
															strVal = strVal.substr(0,70) + '…'
														}
													} else if ( 
														data.humanKeys[key].includes('Nombre') ||
														data.humanKeys[key].includes('Minutes') ||
														data.humanKeys[key].includes('Dossier') ||
														key.includes('nb_') || 
														intKeys.includes( key )
														
													) {
														strVal = new Intl.NumberFormat('fr-CA',{'maximumSignificantDigits':3, 'style':'decimal'}).format( val )
													} else if ( 
														data.humanKeys[key].includes('/') ||
														data.humanKeys[key].includes('%') ||
														percentKeys.includes( key )
													) {
														strVal = new Intl.NumberFormat('fr-CA',{'maximumSignificantDigits':3, 'style':'percent'}).format( val )
													}else{
														strVal = new Intl.NumberFormat('fr-CA',{style:'currency',currency:'CAD'}).format( val )
													}
													
													if ( strVal == 0 ){
														strVal = ''
													}

													return (
														<td title={data.ratios[i][key]} className="value-only">
															{strVal}
														</td>
													)
												})
												: void(0)
										}
									</tr>
								)
							})

						: void(0)
					}
					</tbody>
				</table>
				
			</div>
		</>
	)
}

export default ShowOldRatioList;